<template>
  <pull-to :top-load-method="onRefresh" :top-config="TOP_DEFAULT_CONFIG">
    <div v-if="!dados" class="p-1 pt-6 pb-5 text-center"></div>
    <div class="p-2" v-if="dados">
      <div class="text-center">
        <h3>
          <b-icon-file-check />
          Resumo
        </h3>
      </div>
      <div class="text-left font-weight-bold">
        <b-card body-class="d-flex justify-content-between">
          <span>Valor Abertura</span> R$ {{ dados.valor_entrada | currency }}
        </b-card>
        <b-card class="mt-1" body-class="d-flex justify-content-between">
          <span>Retiradas</span> R$ {{ dados.vl_saidas | currency }}
        </b-card>
        <b-card class="mt-1" body-class="">
          <span>Entregas</span>

          <b-table
            small
            style="font-size:9px"
            class="text-center"
            :items="porValor"
            :fields="[
              { key: 'vl_entrega', text: 'Valor' },
              { key: 'qtd', label: 'Quantidade' },
              { key: 'tot', label: 'Total' },
            ]"
          >
            <template #cell(tot)="row">
              {{ (row.item.vl_entrega * row.item.qtd) | currency }}
            </template>
            <template #cell(vl_entrega)="row">
              {{ row.item.vl_entrega | currency }}
            </template>
          </b-table>
          <hr />
          <div class="row  bg-light p-2" style="font-size:11px">
            <div class="col-4 text-left">
              Total
            </div>
            <div class="col-4 text-center">
              Qtd.:
              {{
                porValor.reduce((ret, vl) => {
                  ret += vl.qtd;
                  return ret;
                }, 0)
              }}
            </div>
            <div class="col-4 text-center">
              {{
                porValor.reduce((ret, vl) => {
                  ret += vl.qtd * vl.vl_entrega;
                  return ret;
                }, 0) | currency
              }}
            </div>
          </div>
        </b-card>
        <b-card class="mt-1" body-class="d-flex justify-content-between">
          <span>Total <small>(-vales)</small></span>
          <span>R$ {{ (dados.vl_total - dados.vl_saidas) | currency }}</span>
        </b-card>

        <!-- 
        <div class="row linhaFina pt-4">
          <div class="col-6">TOTAL</div>
          <div class="col-6 text-right font-weight-light">
            R$ {{ dados.vl_total | currency }}
          </div>
        </div> -->
        <!-- <div class="row linhaFina">
          <div class="col-6">TOTAL - VALES</div>
          <div class="col-6 text-right font-weight-light">
            R$ {{ (dados.vl_total - dados.vl_saidas) | currency }}
          </div>
        </div> -->

        <b-card
          class="mt-1 mb-5"
          body-class="font-weight-normal"
          title="Fechamento"
        >
          <div class="d-flex flex-column w-100">
            <div class="d-flex justify-content-between">
              <span><i class="fa fa-money-bill  " /> Dinheiro</span>

              <span class="text-right">
                R$
                {{
                  ((fechamento.vl_dinheiro == "NaN" ||
                  fechamento.vl_dinheiro == "R$ 0,00"
                    ? "0"
                    : fechamento.vl_dinheiro) || 0) | currency
                }}
                <div
                  class="text-right"
                  v-if="
                    ((fechamento.vl_dinheiro == 'NaN' ||
                    fechamento.vl_dinheiro == 'R$ 0,00'
                      ? '0'
                      : fechamento.vl_dinheiro) || 0) +
                      ((fechamento.vl_moedas == 'NaN' ||
                      fechamento.vl_moedas == 'R$ 0,00'
                        ? '0'
                        : fechamento.vl_moedas) || 0) -
                      dados.vl_dim !=
                      0
                  "
                >
                  <small :class="'text-danger'">
                    Diferença
                    {{
                      (((fechamento.vl_dinheiro == "NaN" ||
                      fechamento.vl_dinheiro == "R$ 0,00"
                        ? "0"
                        : fechamento.vl_dinheiro) || 0) +
                        ((fechamento.vl_moedas == "NaN" ||
                        fechamento.vl_moedas == "R$ 0,00"
                          ? "0"
                          : fechamento.vl_moedas) || 0) -
                        dados.vl_dim)
                        | currency
                    }}
                  </small>
                </div>
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span><i class="fa fa-coins mr-1" /> Moedas</span>

              <span class="text-right">
                R$
                {{
                  ((fechamento.vl_moedas == "NaN" ||
                  fechamento.vl_moedas == "R$ 0,00"
                    ? "0"
                    : fechamento.vl_moedas) || 0) | currency
                }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span><i class="fa fa-credit-card  " /> Cartão</span>
              <span class="text-right">
                R$
                {{
                  ((fechamento.vl_cartao == "NaN" ||
                  fechamento.vl_cartao == "R$ 0,00"
                    ? "0"
                    : fechamento.vl_cartao) || 0) | currency
                }}
                <div
                  class="text-right"
                  v-if="
                    ((fechamento.vl_cartao == 'NaN' ||
                    fechamento.vl_cartao == 'R$ 0,00'
                      ? '0'
                      : fechamento.vl_cartao) || 0) -
                      dados.vl_cartao !=
                      0
                  "
                >
                  <small :class="'text-danger'">
                    Diferença
                    {{
                      (dados.vl_cartao -
                        ((fechamento.vl_cartao == "NaN" ||
                        fechamento.vl_cartao == "R$ 0,00"
                          ? "0"
                          : fechamento.vl_cartao) || 0))
                        | currency
                    }}
                  </small>
                </div>
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span><i class="fa fa-qrcode mr-1" /> PIX</span>
              <span>
                R$
                {{
                  ((fechamento.vl_pix == "NaN" || fechamento.vl_pix == "R$ 0,00"
                    ? "0"
                    : fechamento.vl_pix) || 0) | currency
                }}
              </span>
            </div>
            <div>
              <hr />
            </div>
            <div class="d-flex justify-content-between">
              <span><i class="fa fa-cash-register mr-1" /> Total</span>
              <span>
                R$
                {{
                  (((fechamento.vl_dinheiro == "NaN" ||
                  fechamento.vl_dinheiro == "R$ 0,00"
                    ? "0"
                    : fechamento.vl_dinheiro) || 0) +
                    ((fechamento.vl_cartao == "NaN" ||
                    fechamento.vl_cartao == "R$ 0,00"
                      ? "0"
                      : fechamento.vl_cartao) || 0) +
                    ((fechamento.vl_moedas == "NaN" ||
                    fechamento.vl_moedas == "R$ 0,00"
                      ? "0"
                      : fechamento.vl_moedas) || 0) +
                    ((fechamento.vl_pix == "NaN" ||
                    fechamento.vl_pix == "R$ 0,00"
                      ? "0"
                      : fechamento.vl_pix) || 0))
                    | currency
                }}
                <div></div>
              </span>
            </div>
            <button
              class="btn btn-primary btn-block block mt-4"
              @click="$bvModal.show('modal-fechamento')"
            >
              <i class="fa fa-cash-register"></i> Informar / Editar Fechamento
            </button>
          </div>
        </b-card>

        <!-- <div class="row  mt-4">
          <div class="col-12 text-center linhaFina">
            <h5 class="font-weight-bold">Fechamento Informado</h5>
          </div>
          <div class="col-6 linhaFina"></div>
          <div class="col-6 text-right linhaFina"></div>
          <div class="col-6">Cartão</div>
          <div class="col-6 text-right">
            R$ {{ fechamento.vl_cartao | currency }}
          </div>
          <div class="col-12"></div>
          <div class="col-12" style="display: block">
            <img
              :src="formataURLimagem(fechamento.imagem)"
              style="max-width:100%; display: block"
              :class="{ imgOK: !uploading }"
              v-if="fechamento.imagem"
            />
          </div>
        </div> -->

        <!-- <div class="row linhaFina pt-5 font-weight-bold">
          <div class="col-6">Dinheiro Mãos</div>
          <div class="col-6 text-right font-weight-light">
            R$ {{ dados.vl_total | currency }}
          </div>
        </div> -->
      </div>
    </div>
    <b-modal
      id="modal-fechamento"
      title="Fechamento de Caixa"
      size="sm"
      @ok="salvar"
      @hidden="carregar"
    >
      <h4>VALORES EM MÃOS <i class="fa fa-hand-holding-usd"></i></h4>
      <div class="row">
        <div class="col-12">
          <label><i class="fa fa-money-bill"></i> Dinheiro</label>
          <money
            class="form-control text-center"
            type="text"
            :v-bind="moneyMask"
            pattern="\d*"
            v-model="fechamento.vl_dinheiro"
          />
        </div>

        <div class="col-12">
          <label><i class="fa fa-coins"></i> Moedas</label>
          <money
            class="form-control text-center"
            type="text"
            :v-bind="moneyMask"
            pattern="\d*"
            v-model="fechamento.vl_moedas"
          />
        </div>
        <div class="col-12">
          <label><i class="fa fa-credit-card"></i> Cartão</label>
          <money
            class="form-control text-center"
            type="text"
            pattern="\d*"
            v-money="moneyMask"
            v-model="fechamento.vl_cartao"
          />
        </div>
        <div class="col-12">
          <label><i class="fa fa-qrcode"></i> PIX</label>
          <money
            class="form-control text-center"
            type="text"
            pattern="\d*"
            v-money="moneyMask"
            v-model="fechamento.vl_pix"
          />
        </div>
        <div class="col-12 mt-3">
          <button class="btn btn-sm btn-secondary" @click="addFoto">
            <i class="fa fa-image"></i> Adicionar / Alterar Foto
          </button>
          <input
            type="file"
            ref="txtFile"
            style="visibility: hidden; opacity: 0"
            @change="fileSelected"
          />
          <div
            class="uploading"
            v-if="fechamento.imagem && fechamento.imagem != ''"
          >
            <div v-if="uploading">
              <b-spinner variant="dark"> </b-spinner>
              <br />
              Aguarde...
            </div>
            <img
              :src="formataURLimagem(fechamento.imagem)"
              style="max-width: 100%"
              :class="{ imgOK: !uploading }"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </pull-to>
</template>
<style></style>
<script>
import PullTo from "vue-pull-to";
import axios from "@/utils/axios";
import moment from "moment";

export default {
  components: {
    PullTo,
  },
  data() {
    return {
      TOP_DEFAULT_CONFIG: {
        pullText: "arraste e solte para atualizar", // The text is displayed when you pull down
        triggerText: "arraste e solte para atualizar", // The text that appears when the trigger distance is pulled down
        loadingText: "atualizando...", // The text in the load
        doneText: "atualizado!", // Load the finished text
        failText: "f", // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 50, // Trigger the distance after the refresh
        triggerDistance: 70, // Pull down the trigger to trigger the distance
      },
      dados: {
        valor_entrada: 0,
        vl_dim: 0,
        vl_cartao: 0,
        vl_saidas: 0,
        maquina: "",
        mochila: "",
        vl_pagamento: 0,
        qtd_total: 0,
        vl_entrega: 0,
        vl_total: 0,
      },
      porValor: [],
      data: this.$store.state.data,
      fechamento: {
        cod_fechamento: 0,
        vl_dinheiro: 0,
        vl_cartao: 0,
        imagem: "",
        cod_moto: 0,
        print: false,
      },
      foto: "",
      uploading: false,
    };
  },
  mounted() {
    this.onRefresh(null);
  },
  watch: {
    "$store.state.data": function() {
      this.data = this.$store.state.data;
      this.carregar();
    },
  },
  methods: {
    formataURLimagem(url) {
      if (url.indexOf("blob") >= 0) {
        return url;
      } else {
        return (
          axios.defaults.baseURL +
          "download/" +
          url +
          "?token=" +
          this.$store.state.auth.user.token
        );
      }
    },
    async salvar() {
      this.fechamento.cod_moto = this.dados.cod_moto;

      this.fechamento.data = moment(this.data).format("YYYY-MM-DD");
      this.fechamento.print = false;
      this.fechamento.cod_empresa = this.dados.cod_empresa;
      // this.fechamento.cod_usuario = this.$store.state.auth.user.cod_usuario;
      // console.log("dat", {
      //   ...this.fechamento,
      //   vl_dinheiro: this.formatNumber().dinheiroToFloat(
      //     this.fechamento.vl_dinheiro
      //   ),
      //   vl_cartao: this.formatNumber().dinheiroToFloat(
      //     this.fechamento.vl_cartao
      //   ),
      // });
      await axios.post("/caixas/fechamento/store", {
        fechamento: {
          ...this.fechamento,
          vl_dinheiro: this.fechamento.vl_dinheiro,
          vl_cartao: this.fechamento.vl_cartao,
        },
      });
      this.carregar();
    },
    async fileSelected(evt) {
      evt.preventDefault();
      this.fechamento.imagem = URL.createObjectURL(evt.target.files[0]);
      this.uploading = true;
      let dataForm = new FormData();
      for (let file of evt.target.files) {
        dataForm.append(`file`, file);
      }
      let res = await axios.post("/uploadFile", dataForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res) {
        this.uploading = false;
        this.fechamento.imagem = res.data;
      }
    },
    addFoto() {
      this.fechamento.imagem = "";
      this.$refs.txtFile.value = "";
      this.$refs.txtFile.click();
    },
    async onRefresh(loaded) {
      await this.carregar();
      console.log(this.dados);
      if (loaded) loaded("done");
    },
    async carregar() {
      this.dados = {
        valor_entrada: 0,
        vl_dim: 0,
        vl_cartao: 0,
        vl_moedas: 0,
        vl_pix: 0,
        vl_saidas: 0,
        maquina: "",
        mochila: "",
        vl_pagamento: 0,
        qtd_total: 0,
        vl_entrega: 0,
        vl_total: 0,
      };
      this.porValor = [];
      await axios.post("resumo", { data: this.data }).then(async (x) => {
        if (x && x.data) {
          this.dados = x.data[0];
          console.log("dados", x.data);
          if (x.data.length > 1) {
            this.porValor = x.data[1].porValor;
            this.dados.vl_total = x.data[1].porValor.reduce((ret, vl) => {
              ret += vl.vl_entrega * vl.qtd;
              return ret;
            }, 0);

            let fech = (
              await axios.get("/caixas/fechamento/get", {
                params: {
                  cod_empresa: this.dados.cod_empresa,
                  data: moment(this.data).format("YYYY-MM-DD"),
                  cod_moto: this.dados.cod_moto,
                },
              })
            ).data;

            if (fech.success && fech.data && fech.data.length > 0) {
              this.fechamento = fech.data[0];
              this.fechamento.vl_dinheiro = fech.data[0].vl_dinheiro;

              this.fechamento.vl_cartao = fech.data[0].vl_cartao;
              this.fechamento.vl_moedas = fech.data[0].vl_moedas;
              this.fechamento.vl_pix = fech.data[0].vl_pix;
            }
            console.log("fecha", this.fechamento);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.uploading {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: #333;
  font-weight: bold;
  padding: 15px;
  transition: 0.3s all ease;
  min-height: 200px;
  display: block;
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin-top: -60px;
    margin-left: calc(-10%);
  }
  img {
    opacity: 0.1;
    transition: 0.3s all ease;
  }
  .imgOK {
    opacity: 1;
  }
}
</style>
